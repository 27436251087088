html { 
    height:100%;
}
body { 
  font-family: "Open Sans",Helvetica,Arial,sans-serif;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  background-color: rgb(16, 24, 62) !important;
  color: rgb(250, 255, 249) !important;
}

.text_align_center {
  text-align: center;
}
.text_align_right {
  text-align: right;
}
.text_align_initial {
  text-align: initial;
}

.cursor_pointer {
  cursor: pointer;
}

#loading {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('./images/vnova.gif') center no-repeat;
  background-size: cover;
}

#loading_seek {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('./images/video_seek.gif') center no-repeat;
  background-size: 7%;
  background-color: transparent;
}

.zero_padding {
  padding: 0px !important;
}

.width_18 {
  width: 18px;
}

.padding_left_right_zero {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/*player Styles - starts with 'p'*/
.pclickableContainer {
  /*width:100%;height:100%; position: absolute;z-index:-1; background: black;
  overflow-y: hidden;*/
}
.pvideotag {
  /*background: black;width:100%; height: 100%;object-fit: contain !important;*/display: none;
}
.pcanvastag {
  background: black;width:100% !important; height: 100% !important;object-fit: contain !important;
}
.prewindicon {
  cursor: pointer;position: absolute;margin: auto;top: 0;left: 0;right: 200px;bottom: 0;width: 60px;height: 60px;border-radius: 50%;
}
.playVideo {
  width: 40px;opacity: 0.8;
}
.pplaypauseicon {
  cursor: pointer;position: fixed;margin: -4px auto;left: 0;bottom: 5px;width: 36px;
}
.pforwardicon {
  cursor: pointer;position: absolute;margin: auto;top: 0;left: 200px;right: 0;bottom: 0;width: 60px;height: 60px;border-radius: 50%;
}
.presolutionContainer {
  z-index: 100;padding: 5px 10px;position: absolute;bottom: 40px;
}
.presolutioncontent {
  width: fit-content;background-color: #1a1a1a;color: #FFFFFF;padding: 10px 15px;line-height: 1.5;font-size: 15px;
}
.ptoplabel {
  padding: 8px 0px;background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0.5), 25px,transparent);
  position: absolute; top: 0px; width: 100%;
  z-index: 99;
}
.plcevclabel {
  width: 12%;
  position: absolute;
  margin: 2px 0px;
  top: 8%;
  left: 5px;
}
@media screen and (min-width: 992px) {
  .plcevclabel {
    width: 9%;
    left: 40px;
    top: 7%
  }
}
@media screen and (min-width: 1140px) {
  .plcevclabel {
    left: 70px;
  }
}
@media screen and (min-width: 1200px) {
  .plcevclabel {
    left: 100px;
  }
}
@media screen and (min-width: 1400px) {
  .plcevclabel {
    width: 9%;
    top: 6%;
    left: 140px;
  }
}
@media screen and (min-width: 1500px) {
  .plcevclabel {
    left: 150px;
  }
}
:-webkit-full-screen .plcevclabel {
   left: 5px;
}
:-moz-full-screen .plcevclabel {
  left: 5px;
}

:-ms-fullscreen .plcevclabel {
  left: 5px;
}

:fullscreen .plcevclabel {
  left: 5px;
}
.ptoplabeltitle {
  color:white;padding: 0px 5px;margin: 0px;font-size: 13px;
}
@media screen and (min-width: 350px) {
  .ptoplabeltitle {
    font-size: 15px;
  }
}
@media screen and (min-width: 1300px) {
  .ptoplabeltitle {
    font-size: 17px;
  }
}
@media screen and (min-width: 1400px) {
  .ptoplabeltitle {
    font-size: 19px;
  }
}
.ptoplabelcontainer {
  float:right;display: flex;padding: 0px 5px;
}
.presoultionbtn {
  width: 32px;height: 32px;cursor: pointer;padding: 0px 0px;
}
@media screen and (min-width: 350px) {
  .presoultionbtn {
    width: 35px;
    padding: 0px 2px;
  }
}
@media screen and (min-width: 1400px) {
  .presoultionbtn {
    width: 42px;
    padding: 0px 5px;
  }
}
.pfullscreenbtn {
  padding:0px 0px;padding-top: 0px;cursor:pointer;width: 40px;opacity: 0.8;
}

.pseekbarcontrols {
  z-index: 100;position:absolute;width: 100%;bottom: 4px; padding: 0px 10px;
}
.pseekbarcontainer {
  width: 100%;padding-left: 0px;padding-right: 0px;display: flex;color: #FFFFFF;
}
.pseekbar {
  float:right;width:100%;padding:2px;cursor: pointer;height: 6px;margin-bottom: 10px;margin-top: 8px;border: 0.7px solid #d3d3d3;overflow: hidden;position: relative;
  margin-left: 2px;margin-right: 2px;
}
.pseekbarspan {
  background-color: #FFFFFF;position: absolute;top: 0;left: 0;height: 6px;width: 0px;
}
.pprofilesettings {
  width:33px;background-color: transparent;display: block;/*background-image:url('./images/settings.png');background-repeat: no-repeat;*/background-size: 28px;
    margin-top: 3px;
}
@media screen and (min-width: 350px) {
  .pprofilesettings {
    width:38px;
  }
}
@media screen and (min-width: 1400px) {
  .pprofilesettings {
    width: 45px;
  }
}
.pprofileoptions {
  border: none !important;
  outline: none !important;
}
.pplayercontainer {
  width: 100%;
  height: 100vh;
}
.pplayergoback {
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-top: 5px;
}
@media screen and (min-width: 767px) {
  .pplayergoback {
    height: 22px;
    width: 22px;
  }
}
@media screen and (min-width: 992px) {
  .pplayergoback {
    height: 24px;
    width: 24px;
  }
}
.pvolumebtn {
  cursor: pointer;
  width: 35px;
  height: 40px;
  margin-top: -10px;
  padding: 0px 0px;
}
@media screen and (min-width: 350px) {
  .pvolumebtn {
    width: 40px;
    padding: 0px 2px;
  }
}
@media screen and (min-width: 1400px) {
  .pvolumebtn {
    width: 45px;
    padding: 0px 5px;
  }
}
.outline_none {
  outline: none;
}

input[type=range] {
    /*removes default webkit styles*/
    -webkit-appearance: none;
    
    /*fix for FF unable to apply focus style bug */
    
    /*required for proper track sizing in FF*/
    width: 100px;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100px;
    height: 5px;
    /*background: #ddd;*/
    border: none;
    border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 14px;
    width: 3px;
    /*border-radius: 50%;*/
    background: #ffffff;
    margin-top: -5px;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    /*background: #ccc;*/
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
   /* background: #ddd;*/
    border: none;
    border-radius: 3px;
}
input[type=range]::-moz-range-progress {
    width: 100%;
    height: 5px;
    /*background: red;*/
    border: none;
    border-radius: 3px;
}
input[type=range]::-moz-range-thumb {
    border: none;
    height: 14px;
    width: 3px;
    /*border-radius: 50%;*/
    background: #ffffff;
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: red;
    border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
}
input[type=range]::-ms-thumb {
    border: none;
    height: 14px;
    width: 3px;
    /*border-radius: 50%;*/
    background: #ffffff;
}
input[type=range]:focus::-ms-fill-lower {
    background: #888;
}
input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
}
input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type=password]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=password]::-ms-reveal {  display: none; width : 0; height: 0; }

.seekbar_input_controls {
  /*removes default webkit styles*/
    -webkit-appearance: none;
    
    /*fix for FF unable to apply focus style bug */
    
    /*required for proper track sizing in FF*/
    width: 100px;
    height: 8px;
    /*background: #ddd;*/
    border: none;
    /*border-radius: 3px;*/
    margin-top: 5px;
    /* padding: 6px 52px; */
    /*margin-left: 5px;
    margin-right: 5px;*/
}

.player_seekbar_input {
  background: transparent;
  margin-bottom: 10px;
}

.pcanvastag:-webkit-full-screen {
   width: 100%;
   height: 100%;
   max-height: 100%;
}

/*LCEVC PLAYER*/
.lcevc_player_container {
  display: flex;
  padding: 10px;
  height: 50px;
  background-color: #1f3656;
}
.lcevc_player_url_input {
  width: 80% !important;
  height: auto !important;
  padding: 0px 5px !important;
}
.lcevc_player_url_btn {
  padding: 3px 18px;
  border: none;
  border-radius: 8px;
  color: #333;
  background-color: #edf1f3;
  border-color:#999;
}
.lcevc_player_url_btn:hover {
  color: #e4e6f0;
  background-color: #2f3233;
  border-color:#dddede;
}
.lcevc_player_pplayercontainer {
  width: 100%;
  height: calc(100vh - 40px);
  position: relative;
  background-color: #000000;
  overflow-y: hidden;
}
.lcevc_player_plcevclabel {
  width: 15%;
  position: absolute;
  /*margin: 2px 0px;*/
  top: 10%;
  left: 3%;
  /*background-color: rgba(0,0,0,0.7);*/
  padding: 2px;
  border-radius: 4px;
}
@media screen and (min-width: 767px) {
  .lcevc_player_plcevclabel {
    top: 8%;
  }
}
@media screen and (min-width: 992px) {
  .lcevc_player_plcevclabel {
    /*width: 10%;*/
    top: 5%;
  }
}
.lcevc_player_goback {
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-top: 4px;
}
#lcevc_player_loading {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('./images/vnova.gif') center no-repeat;
  background-size: cover;
}

#toggle_arrow_dropdown {
  position: fixed;
  right: 14px;
  bottom: 40px;
  width: 60px;
  height: 78px;
  background: url('./images/dropdown_white.gif') center no-repeat;
  background-size: cover;
}
@media screen and (min-width: 992px) {
  #toggle_arrow_dropdown {
    right: 6px;
    bottom: 40px;
    width: 80px;
    height: 104px;
  }
}
@media screen and (min-width: 1200px) {
  #toggle_arrow_dropdown {
    right: 30px;
    bottom: 40px;
    width: 80px;
    height: 104px;
  }
}
/*Welcome popup*/
.text_white_color {
  color: #ffffff; 
}
.volume_seek_container {
  display: flex;
  width: 22%;
  margin-bottom: 0px;
  opacity: 0.8;
  /*margin-right: 10px;*/
}
@media screen and (min-width: 350px) {
  .volume_seek_container {
    width: 34%;
  }
}
@media screen and (min-width: 450px) {
  .volume_seek_container {
    width: 45%;
  }
}
@media screen and (min-width: 767px) {
  .volume_seek_container {
    width: 65%;
  }
}
@media screen and (min-width: 992px) {
  .volume_seek_container {
    width: 70%;
  }
}
@media screen and (min-width: 1200px) {
  .volume_seek_container {
    width: 75%;
  }
}
@media screen and (min-width: 1400px) {
  .volume_seek_container {
    width: 75%;
  }
}
@media screen and (min-width: 1800px) {
  .volume_seek_container {
    width: 78%;
  }
}
.toggleLCEVC_container {
  /*text-align: right;*/
  /*width: 100%;*/
  /*margin-top: -5px;*/
  /*padding-left: 20px;*/
  display: flex;
  position: absolute;
  /*margin: 2px 0px;*/
  /*top: 3%;
  left: 2%;*/
  bottom: 2px;
  right: 0px;
  margin-right: 10px;
}

@media screen and (min-width: 1200px) {
  .toggleLCEVC_container {
    margin-right: 35px;
  }
}

@media screen and (min-width: 1400px) {
  .toggleLCEVC_container {
    margin-right: 40px;
  }
}
/*.toggleLCEVC_img {
  width: 15%;
}*/
.toggleLCEVC_img img {
  width: 80px;
  margin-top: 7px;
}

@media screen and (min-width: 350px) {
  .toggleLCEVC_img img {
    width: 80px;
    margin-top: 4px;
  }
}
@media screen and (min-width: 600px) {
  .toggleLCEVC_img img {
    width: 100px;
  }
}
@media screen and (min-width: 992px) {
  .toggleLCEVC_img img {
    width: 120px;
    margin-top: 2px;
  }
}
@media screen and (min-width: 1400px) {
  .toggleLCEVC_img img {
    width: 140px;
    margin-top: 0px;
  }
}
.lcevc_toggle_logo {
  width: 15%;
  background-color: rgba(0,0,0,0.7);
  padding: 2px;
  /*border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;*/
  /*margin-right: 10px;*/
}
.onoffswitch {
  /*position: relative;
  padding-left: 10px;
  background-color: rgba(0,0,0,0.7);
  padding: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;*/
    /*position: absolute; width: 70px;bottom: 65px;
    right: 0;*//*height: 35px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;*/
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #999999; border-radius: 20px;margin: 0px;height: 28px;
}

.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s; -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s; transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
    font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "ON";
    padding-left: 5px;
    margin-top: -2px;
    background-color: #ffffff; color: #000000;
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 5px;
    margin-top: -2px;
    background-color: #B3B3B3; color: #000000;
    text-align: right;
}

.onoffswitch-switch {
    display: block; width: 12px; height: 12px; /*margin: 6px;*/
    background: #000000;
    border: 1px solid #999999; border-radius: 20px;
    position: absolute; top: 8.5px; bottom: 0; right: 42px;
    -moz-transition: all 0.3s ease-in 0s; -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s; transition: all 0.3s ease-in 0s; 
}
@media screen and (min-width: 992px) {
  .onoffswitch-switch {
    right: 45px;
    width: 14px; height: 14px;
    top: 8px;
  }
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 4px;
    top: 8.5px; 
}

@media screen and (min-width: 992px) {
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 4px;
    top: 8px; 
  }
}

.lcevc_player_controls_container {
  height: 40px;
  width: 100%;position: absolute;bottom: 0px;
  background-color: #000000;
}

/*New login signup setup*/

.headerforweb {
  z-index: 100;
  /*position: absolute;*/
  /*width: 100%;*/
  padding-top: 10px;
  background: linear-gradient(to bottom,rgba(0, 0, 0, 0.3) 40%,transparent);
}
.tab_logo {
  width: 50px;
  /*height: 45px;*/
  cursor: pointer;
  margin-top: 3px;
  margin-left: 39px;
}
@media screen and (min-width: 767px) {
 .tab_logo {
    width: 60px;
    margin-left: 42px;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
  margin-top: 8px;
  float: right;
  padding-right: 15px;
}
@media screen and (min-width: 767px) {
 .dropdown {
    padding-right: 10px;
  }
}
.dropdown img {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  opacity: 0.8;
  cursor: pointer;
}
@media screen and (min-width: 767px) {
 .dropdown img {
    width: 32px;
    height: 32px;
  }
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(53, 53, 53, 0.5);
  min-width: 120px;
  z-index: 1;
  margin-left: -85px;
  text-align: initial;
  box-shadow: 0px 0px 10px 2px #9b9b9b;
  margin-top: -3px;
}
.dropdown-content a {
  color: #ffffff;
  padding: 6px 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {color: #ffffff;text-decoration: none;}

.dropdown:hover .dropdown-content {display: block;}

.clips_container {
  padding: 0px !important;
  margin-bottom: 18px;
}
.clips_content {
  padding: 5px 5px !important;
}
.clips_img {
  width: 100%;
  /*height: calc((100vh/16)*9);*/
  /*height: calc(100vw/16*9/2);*/
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #5d5d5d;
}
.clips_content:hover .clips_img {
  box-shadow: 0px 0px 5px 3px #9b9b9b;
}
.clips_play_btn {
  cursor: pointer;position: absolute;margin: auto;top: 0;left: 0;right: 0;bottom: 0;width: 60px;height: 60px;filter: opacity(85%);
  opacity: 0;background-image:url('./images/1.png');background-repeat: no-repeat;background-size: contain;
  visibility: hidden;
  transition: opacity .5s, visibility .5s;
}
@media screen and (min-width: 500px) {
  .clips_play_btn {
    width: 80px;height: 80px;
  }
}
@media screen and (min-width: 767px) {
  .clips_play_btn {
    width: 100px;height: 100px;
  }
}
.clips_content:hover .clips_play_btn {
  visibility: visible;
  opacity: 1;
}

.text {
  font-size: 9px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: absolute;
  /*top: 0;*/
  bottom: 10px;
  left: 0;
  right: 0;
  padding: 4px;
  margin-right: 10px;
  margin-left: 10px;
  min-height: 50px;
  filter: opacity(85%);
  border-radius: 4px;
  opacity: 0;
}

@media screen and (min-width: 480px) {
  .text {
    font-size: 10px;
  }
}

@media screen and (min-width: 767px) {
  .text {
    font-size: 11px;
  }
}
@media screen and (min-width: 960px) {
  .text {
    font-size: 12px;
  }
}

.wrapword {
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    white-space: pre-wrap;       /* css-3 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
    word-break: break-word;
    white-space: normal;
}
.footer_version {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: rgba(16, 24, 62, 0.5);
}
/*MyAccount and About section*/
.assetdetail_logo_myaccount {
  width: 80px;
  cursor: pointer;
  padding-top: 50px;
}
@media screen and (min-width: 767px) {
 .assetdetail_logo_myaccount {
    width: 100px;
  }
}
@media screen and (min-width: 1400px) {
 .assetdetail_logo_myaccount {
    width: 120px;
  }
}
.myaccount_text {
  padding-top: 8px;
  font-size: 16px;
  font-weight: 600;
}
.detailgoback_span {
  float: left;
  padding-top: 10px;
  padding-left: 10px;
}
.detailgoback {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.myaccount_container {
  text-align: center;
  padding-top: 80px;
  font-size: 15px;
}
@media screen and (min-width: 1500px) {
  .myaccount_container {
    padding-top: 120px;
    font-size: 16px;
  }
}
.myaccount_details {
  padding: 10px 0px;
}
.myaccount_details_name {
  color: #dbdbdb;
}
.expWelcomePopup_modal_body {
  padding: 10px 15px !important;
}
.expWelcomePopup_modal_dialog {
  width: max-content;
  margin: auto;
  /*top: 15%;*/
  -webkit-transform: translate3d(0%, 30%, 0) !important;
  -moz-transform: translate3d(0%, 30%, 0) !important;
  transform: translate3d(0%, 30%, 0) !important;
  font-size: 14.5px;
}
.expWelcomePopup_modal_content {
  background-color: #003B75 !important;
  color: #ffffff;
  box-shadow: 0px 0px 10px 5px #9b9b9b !important;
}
.expWelcomePopup_btn {
  padding: 0px 0px 10px;
  text-align: end;
}
.checkbox_input {
  width: 24px;height: 14px;outline: none !important;
  margin-top: 3px !important;
}
.position_absolute {
  position: absolute;
}
.popup_welcomeImg {
  width: 100%;
}
.popup_welcomeLCEVC {
  width: 30%;
}
.popup_welcomeToggle {
  width: 15%;
}
/*Hide the default LCEVC Logo for the build*/
.pclickableContainer img {
  display: none !important;
}

#videoContainer > img {
  margin-left: 40px !important;
}

.shaka-canvas-container {
  object-fit: contain !important;
}